import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Container, Row, Col } from 'react-grid-system';
import Alert from '../../components/Alert/Alert';
import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';

const OurRolePage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			buyersImg: file(relativePath: { eq: "coupleCondo.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			breadcrumb={{
				text: <FormattedMessage id='nav.about' />,
				path: '/about/'
			}}
			header={<FormattedMessage id='nav.ourRole' />}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.ourRoleDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.ourRole' })}
			/>
			<Container>
				<Row align='center'>
					<Col>
						<Img fluid={data.buyersImg.childImageSharp.fluid} alt='' />
					</Col>
					<Col xl={8} md={12}>
						<h2>
							<FormattedMessage id='ourRole.heading01' />
						</h2>
						<p>
							<FormattedMessage id='ourRole.content01A' />
						</p>
						<p>
							<FormattedMessage id='ourRole.content01B' />
						</p>
						<p>
							<FormattedMessage id='ourRole.content01C' />
						</p>

						<h2>
							<FormattedMessage id='ourRole.heading02' />
						</h2>
						<p>
							<FormattedMessage id='ourRole.content02A' />
							<br />
							<br />
							<FormattedMessage id='ourRole.content02B' />
						</p>

						<h2>
							<FormattedMessage id='ourRole.heading04' />
						</h2>
						<p>
							<FormattedMessage id='ourRole.content04A' />
							<br />
							<br />
							<FormattedMessage id='ourRole.content04B' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col md={12}>
						{intl.locale === 'fr' && (
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						)}
						{/* <h2>
              <FormattedMessage id='ourRole.heading03' />
            </h2>
            <h3>
              <FormattedMessage id='ourRole.subhdr03A' />
            </h3>
            <p>
              <FormattedMessage id='ourRole.content03' />
            </p>

            <h3>
              <FormattedMessage id='ourRole.subhdr03B' />
            </h3>
            <ul>
              <li>
                <FormattedMessage id='ourRole.list03E' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03F' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03G' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03H' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03I' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03J' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03K' />
              </li>
              <li>
                <FormattedMessage id='ourRole.list03L' />
              </li>
            </ul> */}
						<h2>Mission and Values</h2>
						<h3>Mission:</h3>
						<p>
							A fair, effective, and proactive regulator of new home builders and vendors that ensures a
							positive consumer experience.
						</p>

						<h3>Values:</h3>
						<ul>
							<li>Service excellence</li>
							<li>Fairness</li>
							<li>Integrity</li>
							<li>Diversity and inclusion</li>
							<li>Respectfulness</li>
							<li>Learning and innovating</li>
							<li>Continuous improvement</li>
							<li>Openness and accountability</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default OurRolePage;
